import calculateScrollbarWidth from "./helpers/calculateScrollBarWidth";
import 'bootstrap.native/dist/bootstrap-native-v4';
import { Datepicker } from 'vanillajs-datepicker';
import * as Navigation from "Modules/navigation";
import * as Search from "./modules/search";

let module = {};

module.setupDatepicker = () => {
	let theDate = document.querySelectorAll('.js-date');

	if(!theDate.length){
		return;
	}
	theDate.forEach((dateField, i) => {
		window[`datepicker${i}`] = new Datepicker(dateField, {
			buttonClass: 'btn',
		});
	});
};

module.init = () => {
	calculateScrollbarWidth();
	module.setupDatepicker();
	Navigation.init();
	if(document.querySelector(".js-header-search")) {
		Search.init();
	}
};

window.KMW = window.KMW || {};
window.KMW.main = module;

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', module.init);
} else {
	module.init();
}

function toggleModal(event) {
	let modal;
	if (event?.target?.getAttribute("data-target")) {
		modal = document.getElementById(event.target.getAttribute("data-target"));
	} else {
		modal = event.target;
	}
	modal.classList.toggle("show-modal");
}

function windowOnClick(event) {
	let modal = document.querySelector(".show-modal");
	if (event.target === modal) {
		toggleModal(event);
	}
}

document.querySelectorAll('.trigger, .close-button').forEach(trigger => {
	trigger.addEventListener('click', toggleModal)
})

window.addEventListener("click", windowOnClick);
