//import * as enquire from 'enquire.js';
//import Masonry from "masonry-layout";
//import { throttle } from "throttle-debounce";

let setupNav = () => {
	const navRoots = document.querySelectorAll('.navbar-nav');

	navRoots.forEach(el => {
		el.addEventListener('click', (e) => {
			if (e.target.matches('.dropdown-toggle') || e.target.closest('.dropdown-toggle')) {
				const nextToggle = e.target.closest('.dropdown-toggle');
				const nextDropdownMenu = e.target.closest('.dropdown').querySelector('.dropdown-menu');
				const isEmptyLink = nextToggle.getAttribute('href') === '#';

				if (nextDropdownMenu && (isEmptyLink || !nextDropdownMenu.classList.contains('show'))) {
					e.preventDefault();
				}
			}
		});
	});

	const navElementsWithDropdown = document.querySelectorAll('.nav-item.dropdown');
	for (const dropdown of navElementsWithDropdown) {
		dropdown.addEventListener('mouseenter', (e) => {
			const nextDropdownMenu = e.target.querySelector('.dropdown-menu');
			if (nextDropdownMenu) {
				nextDropdownMenu.classList.add('show');
			}
		});

		dropdown.addEventListener('mouseleave', (e) => {
			const nextDropdownMenu = e.target.querySelector('.dropdown-menu');
			if (nextDropdownMenu) {
				nextDropdownMenu.classList.remove('show');
			}
		});
	}

};


export function init() {
	setupNav();
}
