import { debounce } from "throttle-debounce"

/* Search-related functions */
export function init() {
	let searchBox = document.querySelector(".js-header-search")
	let searchSubmit = document.querySelector(".js-header-search-submit")
	let searchCancel = document.querySelector(".js-header-search-cancel")
	let searchCancelMobile = document.querySelector(".js-header-search-cancel-mobile")
	let searchInput = document.querySelector(".js-header-search-input")
	let searchResults = document.querySelector(".js-header-search-results")
	let mainNav = document.querySelector(".js-main-nav")

	let lastSearchTerm = ""

	searchSubmit.addEventListener("click", (event) => {
		event.preventDefault()
		event.stopPropagation()
		searchBox.classList.toggle("state-search-visible")

		if (searchBox.classList.contains("state-search-visible")) {
			searchInput.focus()
			searchInput.addEventListener("blur", closeSearch)
		} else {
			searchResults.classList.remove("state-search-results-visible")
			searchInput.blur()
		}
	})

	searchInput.addEventListener("keyup", debounce(500, processSearch))

	searchCancel.addEventListener("click", closeSearch)
	searchCancelMobile.addEventListener("click", closeSearchMobile)

	function closeSearch(event) {
		event.preventDefault()
		event.stopPropagation()
		searchBox.classList.remove("state-search-visible")
		searchInput.value = ""
		mainNav.classList.remove("state-out")
		searchResults.classList.remove("state-search-results-visible")
		searchInput.removeEventListener("keyup", debounce(500, processSearch))
		searchCancel.removeEventListener("click", closeSearch)
		searchInput.removeEventListener("blur", closeSearch)
	}

	function closeSearchMobile(event) {
		event.preventDefault()
		event.stopPropagation()
		searchInput.value = ""
		searchResults.classList.remove("state-search-results-visible")
		searchCancelMobile.classList.remove("icon-times")
		searchCancelMobile.classList.add("icon-search")
	}

	function processSearch(event) {
		let inputValue = event.target.value

		if (inputValue.trim().length && inputValue !== lastSearchTerm) {
			searchResults.classList.add("state-search-results-visible")
			searchCancelMobile.classList.remove("icon-search")
			searchCancelMobile.classList.add("icon-times")
			//searchApiCall()
		} else if (inputValue.trim() === "") {
			searchResults.classList.remove("state-search-results-visible")
			searchCancelMobile.classList.remove("icon-times")
			searchCancelMobile.classList.add("icon-search")
		}

		lastSearchTerm = inputValue

		//enter click
		if (event.keyCode === 13 && inputValue.length) {
			alert("Simulated transition to a search page upon clicking enter.")
			//let searchUrl = "http://localhost:4000"
			//window.location.href = searchUrl + "?q=" + event.target.value
		}
	}
}
