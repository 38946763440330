export default function calculateScrollbarWidth() {
	let el = document.createElement('div');
	el.setAttribute('style', 'width:100%');
	document.body.appendChild(el);
	let constrainedWidth = el.offsetWidth;

	el.setAttribute('style', 'width:100vw');
	let fullWidth = el.offsetWidth;

	let scrollbarWidth = Math.ceil(fullWidth - constrainedWidth);

	document.documentElement.style.setProperty(
		'--scrollbar-width',
		scrollbarWidth + 'px'
	);

	document.body.removeChild(el);
}
